import React from 'react';
import ReactDOM from 'react-dom/client';
import PWeek2024 from './PWeek2024';
import PWeek2025 from './PWeek2025';
import Retreats2024 from './Retreats2024';
import AIWordCloud from './AIWordCloud';
import Forumdle from './Forumdle';
import { Analytics } from '@vercel/analytics/react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/pweek-2024',
    element: <PWeek2024 />,
  },
  {
    path: '/pweek-2025',
    element: <PWeek2025 />,
  },
  {
    path: '/retreats-2024',
    element: <Retreats2024 />,
  },
  {
    path: '/ai-word-cloud',
    element: <AIWordCloud />,
  },
  {
    path: '/forumdle',
    element: <Forumdle />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <Analytics />
  </React.StrictMode>
);
