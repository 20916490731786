import { useEffect, useState } from 'react';
import { track } from '@vercel/analytics/react';
import clsx from 'clsx';

export default function Forumdle() {
  const today = new Date().toLocaleDateString('en-US', { timeZone: 'America/Chicago' });
  const [guesses, setGuesses] = useState(() => {
    const savedDate = localStorage.getItem('date');
    if (savedDate !== today) {
      localStorage.clear();
      localStorage.setItem('date', today);
    }
    const savedGuesses = localStorage.getItem('guesses');
    return savedGuesses ? JSON.parse(savedGuesses) : [];
  });
  const [draft, setDraft] = useState('');
  const [dictionary, setDictionary] = useState([]);
  const [startTime, setStartTime] = useState(Date.now());
  const [accumulatedTime, setAccumulatedTime] = useState(() => {
    const savedTime = localStorage.getItem('accumulatedTime');
    return savedTime ? parseInt(savedTime, 10) : 0;
  });
  const { word: answer, story } = answers[today] || Object.values(answers)[0];

  const [hasWon, setHasWon] = useState(() => {
    const savedHasWon = localStorage.getItem('hasWon');
    return savedHasWon ? JSON.parse(savedHasWon) : false;
  });

  const submitGuess = () => {
    if (draft.length !== 5) {
      alert('Word must be 5 letters');
      return;
    }

    if (!dictionary.includes(draft.toLowerCase())) {
      alert('Invalid word');
      return;
    }

    const newGuesses = [...guesses, draft.split('')];
    setDraft('');
    setGuesses(newGuesses);
    localStorage.setItem('guesses', JSON.stringify(newGuesses));

    const currentTime = Date.now();
    const sessionTime = (currentTime - startTime) / 1000;
    const totalTime = accumulatedTime + sessionTime;

    if (newGuesses.length === 6) {
      track('forumdleComplete', {
        date: today,
        word: answer,
        attempts: newGuesses.length,
        guesses: newGuesses,
        won: false,
        duration: totalTime // total duration in seconds
      });
    }

    if (draft === answer) {
      setHasWon(true);
      track('forumdleComplete', {
        date: today,
        word: answer,
        attempts: newGuesses.length,
        guesses: newGuesses,
        won: true,
        duration: totalTime // total duration in seconds
      });
      localStorage.setItem('hasWon', JSON.stringify(true));
    }

    setAccumulatedTime(totalTime);
    localStorage.setItem('accumulatedTime', totalTime);
    setStartTime(currentTime);
  }

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/dwyl/english-words/refs/heads/master/words.txt', {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
      }
    })
      .then((response) => response.text())
      .then((data) => {
        setDictionary(data.toLowerCase().split('\n'));
      });

    const handleKeyPress = (e) => {
      if (hasWon) return;

      if (e.key === 'Enter') {
        submitGuess();
      } else if (e.key === 'Backspace') {
        setDraft((prevDraft) => prevDraft.slice(0, -1));
      } else if (/^[a-zA-Z]$/.test(e.key) && draft.length < 5) {
        setDraft((prevDraft) => prevDraft + e.key.toUpperCase());
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [draft, guesses]);

  useEffect(() => {
    if (localStorage.getItem('date') !== today) {
      localStorage.setItem('date', today);
      localStorage.removeItem('hasWon');
      localStorage.removeItem('guesses');
      localStorage.removeItem('accumulatedTime');
      setStartTime(Date.now()); // reset start time for new day
      setAccumulatedTime(0); // reset accumulated time for new day
    }

    const handleBeforeUnload = () => {
      const currentTime = Date.now();
      const sessionTime = (currentTime - startTime) / 1000;
      const totalTime = accumulatedTime + sessionTime;
      localStorage.setItem('accumulatedTime', totalTime);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [startTime, accumulatedTime, today]);

  const getLetterStatuses = (guess, answer) => {
    // Initialize all statuses to "incorrect"
    const statuses = Array(5).fill('incorrect');
    const answerLetterCount = {};

    // Count how many of each letter appears in the answer.
    for (let i = 0; i < answer.length; i++) {
      const letter = answer[i];
      answerLetterCount[letter] = (answerLetterCount[letter] || 0) + 1;
    }

    // First pass: Mark correct letters (greens) and reduce the available count.
    for (let i = 0; i < 5; i++) {
      if (guess[i] === answer[i]) {
        statuses[i] = 'correct';
        answerLetterCount[guess[i]]--;
      }
    }

    // Second pass: Mark wrong-position letters (yellows)
    for (let i = 0; i < 5; i++) {
      if (statuses[i] !== 'correct' && guess[i] && answerLetterCount[guess[i]] > 0) {
        statuses[i] = 'wrong-position';
        answerLetterCount[guess[i]]--;
      }
    }

    return statuses;
  };

  return (
    <>
      <div id="wordle">
        <div>
          <table>
            {
              [0, 1, 2, 3, 4, 5].map((rowIndex) => {
                // Completed guesses
                if (rowIndex < guesses.length) {
                  const guess = guesses[rowIndex];
                  const statuses = getLetterStatuses(guess, answer);
                  return (
                    <tr key={rowIndex}>
                      {guess.map((letter, colIndex) => (
                        <td key={colIndex} className={clsx(statuses[colIndex])}>
                          {letter}
                        </td>
                      ))}
                    </tr>
                  );
                }
                // Current guess (in-progress)
                else if (rowIndex === guesses.length) {
                  return (
                    <tr key={rowIndex}>
                      {[0, 1, 2, 3, 4].map((_, colIndex) => (
                        <td key={colIndex}>
                          {draft[colIndex] || ''}
                        </td>
                      ))}
                    </tr>
                  );
                }
                // Empty rows
                else {
                  return (
                    <tr key={rowIndex}>
                      {[0, 1, 2, 3, 4].map((_, colIndex) => (
                        <td key={colIndex} />
                      ))}
                    </tr>
                  );
                }
              })
            }
          </table>


          <div className="keyboard">
            <div className="row">
              {['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map((letter) => (
                <button
                  key={letter}
                  disabled={hasWon}
                  onClick={() => setDraft((prevDraft) => prevDraft + letter)}
                  className={clsx(
                    guesses.some(guess => guess.includes(letter) && guess[guesses[guesses.indexOf(guess)].indexOf(letter)] === answer[guesses[guesses.indexOf(guess)].indexOf(letter)]) ? 'correct-kb' :
                      guesses.some(guess => guess.includes(letter) && answer.includes(letter)) ? 'wrong-position-kb' :
                        guesses.some(guess => guess.includes(letter)) ? 'incorrect-kb' : ''
                  )}
                >
                  {letter}
                </button>
              ))}
              <button disabled={hasWon} onClick={() => setDraft((prevDraft) => prevDraft.slice(0, -1))}>⌫</button>
            </div>
            <div className="row">
              {['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map((letter) => (
                <button
                  key={letter}
                  disabled={hasWon}
                  onClick={() => setDraft((prevDraft) => prevDraft + letter)}
                  className={clsx(
                    guesses.some(guess => guess.includes(letter) && guess[guesses[guesses.indexOf(guess)].indexOf(letter)] === answer[guesses[guesses.indexOf(guess)].indexOf(letter)]) ? 'correct-kb' :
                      guesses.some(guess => guess.includes(letter) && answer.includes(letter)) ? 'wrong-position-kb' :
                        guesses.some(guess => guess.includes(letter)) ? 'incorrect-kb' : ''
                  )}
                >
                  {letter}
                </button>
              ))}
            </div>
            <div className="row">
              {['Z', 'X', 'C', 'V', 'B', 'N', 'M'].map((letter) => (
                <button
                  key={letter}
                  disabled={hasWon}
                  onClick={() => setDraft((prevDraft) => prevDraft + letter)}
                  className={clsx(
                    guesses.some(guess => guess.includes(letter) && guess[guesses[guesses.indexOf(guess)].indexOf(letter)] === answer[guesses[guesses.indexOf(guess)].indexOf(letter)]) ? 'correct-kb' :
                      guesses.some(guess => guess.includes(letter) && answer.includes(letter)) ? 'wrong-position-kb' :
                        guesses.some(guess => guess.includes(letter)) ? 'incorrect-kb' : ''
                  )}
                >
                  {letter}
                </button>
              ))}
            </div>
            <div className="row">
              <button disabled={hasWon} onClick={submitGuess}>Submit</button>
            </div>
          </div>
        </div>

        {hasWon && <div>
          <h1>🏆<br />Good job!</h1>
          <p>Today's word is from:</p>
          <a target="_parent" href={story.link + '?utm_source=completion&utm_medium=forumdle'} className="story-link">
            <div className="story-container">
              <img src={story.image} className="story-image-cropped" />
              <div className="story-card">
                <h2>{story.title}</h2>
                <p>{story.author} • {story.date}</p>
              </div>
            </div>
          </a>
        </div>}

        {(guesses.length === 6 && !hasWon) && <div>
          <h1>❌<br />Maybe tomorrow...</h1>
          <p>Today's word is {answer.toUpperCase()}, from:</p>
          <a target="_parent" href={story.link + '?utm_source=completion&utm_medium=forumdle'} className="story-link">
            <div className="story-container">
              <img src={story.image} className="story-image-cropped" />
              <div className="story-card">
                <h2>{story.title}</h2>
                <p>{story.author} • {story.date}</p>
              </div>
            </div>
          </a>
        </div>}
      </div>
    </>
  )
}

const answers = {
  '1/1/1970': {
    word: 'NIGHT',
    story: {
      title: 'The Senior Overnight Retreat Returns',
      link: 'https://readtheforum.org/23673/news/the-senior-overnight-retreat-returns/',
      author: 'Caroline McHugh',
      date: 'September 4, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/09/IMG_7847-1200x900.jpg'
    }
  },
  '2/19/2025': {
    word: 'SAUCE',
    story: {
      title: 'All About the Sauce',
      link: 'https://readtheforum.org/22726/op/all-about-the-sauce/',
      author: 'Ashton Seymore',
      date: 'February 15, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/02/Image-2-14-24-at-5.08-PM.jpg'
    }
  },
  '2/20/2025': {
    word: 'QUEST',
    story: {
      title: 'Charting New Territories: The Quest for Ideal Project Week Locations',
      link: 'https://readtheforum.org/22710/features/charting-new-territories-the-quest-for-ideal-project-week-locations/',
      author: 'Myles Antelis and Carla Di Silvestro',
      date: 'February 15, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/02/Image-2-14-24-at-4.37-PM-1200x1037.jpg',
    }
  },
  '2/21/2025': {
    word: 'HEART',
    story: {
      title: 'Art with Heart: Latin Seniors Craft a Vision of Sustainability, Community, and Sparkle',
      link: 'https://readtheforum.org/23401/arts/art-with-heart-latin-seniors-craft-a-vision-of-sustainability-community-and-sparkle/',
      author: 'Roxie Lara',
      date: 'May 1, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/05/galery.png'
    }
  },
  '2/23/2025': {
    word: 'MAGIC',
    story: {
      title: 'Magic: The Gathering Casts a Spell Over Seniors',
      link: 'https://readtheforum.org/24221/features/magic-the-gathering-casts-a-spell-over-seniors/',
      author: 'Sara Cutinho and Ben Gibson',
      date: 'November 4, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/11/IMG_2819.jpg'
    }
  },
  '2/24/2025': {
    word: 'BUDDY',
    story: {
      title: 'K-12 United for Roman Buddy Events',
      link: 'https://readtheforum.org/23894/news/k-12-united-for-roman-buddy-events/',
      author: 'Ellie Anderson',
      date: 'October 1, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/10/buddy-scaled.jpg'
    }
  },
  '2/25/2025': {
    word: 'POLLS',
    story: {
      title: 'First-Time Voters Visit the Polls',
      link: 'https://readtheforum.org/24308/news/first-time-voters-visit-the-polls/',
      author: 'Anderson Miller',
      date: 'November 7, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/11/Untitled-design.jpg'
    }
  },
  '2/26/2025': {
    word: 'DELAY',
    story: {
      title: 'The Art of Procrastination: Embracing the Creative Power of Delay',
      link: 'https://readtheforum.org/22592/op/the-art-of-procrastination-embracing-the-creative-power-of-delay/',
      author: 'Quinn Healy',
      date: 'February 1, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/02/Screenshot-2024-02-01-at-9.59.47%E2%80%AFAM-879x1200.png'
    }
  },
  '2/27/2025': {
    word: 'TREAT',
    story: {
      title: 'Parents Make Sweet and Stylish Sweatshirt Treat for Seniors',
      link: 'https://readtheforum.org/23927/news/parents-make-sweet-and-stylish-sweatshirt-treat-for-seniors/',
      author: 'Gavin Addison',
      date: 'October 1, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/10/image1-1200x675.jpg'
    }
  },
  '2/28/2025': {
    word: 'BONDS',
    story: {
      title: 'Forging Bonds on the Freshman Retreat',
      link: 'https://readtheforum.org/23680/news/forging-bonds-on-the-freshman-retreat/',
      author: 'Sloane Singerman',
      date: 'September 4, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/09/Screenshot-2024-09-03-at-6.17.44%E2%80%AFPM-1200x890.png'
    }
  },
  '3/2/2025': {
    word: 'HOUSE',
    story: {
      title: 'DJ Germain: Latin’s House Music Hero',
      link: 'https://readtheforum.org/23904/arts/dj-germain-latins-house-music-hero/',
      author: 'Rohin Shah',
      date: 'October 1, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/10/IMG_4752.jpg'
    }
  },
  '3/3/2025': {
    word: 'CRUSH',
    story: {
      title: 'Latin Alumni Crush Chicago Marathon',
      link: 'https://readtheforum.org/24257/sports/latin-alumni-crush-chicago-marathon/',
      author: 'Ellie Anderson and Pippa Brink',
      date: 'November 4, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/11/Screenshot-2024-10-19-at-3.04.16-PM-983x1200.png'
    }
  },
  '3/4/2025': {
    word: 'TIMES',
    story: {
      title: 'Good Times, No Tan Lines',
      link: 'https://readtheforum.org/23582/features/good-times-no-tan-lines/',
      author: 'Eva Lapiere',
      date: 'June 4, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/06/sky-sun-clouds-70930d-1024.jpg'
    }
  },
  '3/5/2025': {
    word: 'LOCAL',
    story: {
      title: 'Spotlight on Local Politics: Alderman Hopkins Tackles Crime in Chicago',
      link: 'https://readtheforum.org/23612/features/spotlight-on-local-politics-alderman-hopkins-tackles-crime-in-chicago/',
      author: 'Carla Di Silvestro',
      date: 'June 4, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/06/SCR-20240604-rgmb.png'
    }
  },
  '3/6/2025': {
    word: 'COINS',
    story: {
      title: 'Minting Memories',
      link: 'https://readtheforum.org/23083/project-week-2024/minting-memories/',
      author: 'Edie Park',
      date: 'March 21, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/03/IMG_9151.jpg'
    }
  },
  '3/7/2025': {
    word: 'STEAL',
    story: {
      title: 'Seniors and Teachers Steal the Show in Halloween Contest',
      link: 'https://readtheforum.org/24154/news/seniors-and-teachers-steal-the-show-in-halloween-contest/',
      author: 'Roxie Lara',
      date: 'November 2, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/11/halloween3-1200x900.jpg'
    }
  },
  '3/8/2025': {
    word: 'FOCUS',
    story: {
      title: 'Defiance in Focus: A Photographic Exploration of ‘Antigone Now’',
      link: 'https://readtheforum.org/23304/arts/defiance-in-focus-a-photographic-exploration-of-antigone/',
      author: 'Cherish Curtis',
      date: 'April 24, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/04/tempImagec3Hfce-scaled.jpg'
    }
  },
  '3/10/2025': {
    word: 'ALUMS',
    story: {
      title: 'Juniors Meet Latin Alums During Retreat',
      link: 'https://readtheforum.org/23659/news/juniors-meet-latin-alums-during-retreat/',
      author: 'Scarlet Gitelson',
      date: 'September 4, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/09/IMG_0061-1200x675.jpg'
    }
  },
  '3/12/2025': {
    word: 'GUEST',
    story: {
      title: 'Latin Faces Challenges Vetting Guest Speakers',
      link: 'https://readtheforum.org/23619/features/latin-faces-challenges-vetting-guest-speakers/',
      author: 'Rohin Shah and Cherish Curtis',
      date: 'June 14, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/06/Screenshot-2024-06-08-at-7.39.32-PM.png'
    }
  },
  '3/14/2025': {
    word: 'SHAKE',
    story: {
      title: 'Unexpected Faculty Departures Shake Up School Semester',
      link: 'https://readtheforum.org/22532/news/unexpected-faculty-departures-shake-up-school-semester/',
      author: 'Caroline McHugh',
      date: 'January 16, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/01/fQZi9TSTMfOz9u86VPSqrmuw2TEgpD7mNi2sWDhG-902x1200.png'
    }
  },
  '3/15/2025': {
    word: 'BLOCK',
    story: {
      title: 'My Block, My Hood, My City founder Jahmal Cole Speaks at Gathering',
      link: 'https://readtheforum.org/23749/news/jahmal-cole-speaks-at-gathering/',
      author: 'Carla McSweeney and Abdon Valenciana',
      date: 'September 23, 2024',
      image: 'https://readtheforum.org/wp-content/uploads/2024/09/Screenshot-2024-09-20-at-6.04.27%E2%80%AFPM.png'
    }
  },
  '3/16/2025': {
    word: 'STARS',
    story: {
      title: 'Stars and Surprises at the Oscars',
      link: 'https://readtheforum.org/25784/arts/stars-and-surprises-at-the-oscars/',
      author: 'Natasha Benjamin',
      date: 'March 7, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/4GOit2qeWIGeEDrnSpOLf2zMKgs0sjjdRSae22aT-1200x543.jpg'
    }
  },
  '3/17/2025': {
    word: 'CROWN',
    story: {
      title: 'Latin Sophomore Crowned Miss Windy City’s Teen',
      link: 'https://readtheforum.org/25818/news/latin-sophomore-crowned-miss-windy-citys-teen/',
      author: 'Ellie Anderson',
      date: 'March 7, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/h6k70eQzIHp4PS6mVfmScNiYnQVMk9EJjbEMe49W.jpg'
    }
  },
  '3/18/2025': {
    word: 'MAGIC',
    story: {
      title: 'Latin Students Spend P-Week Embarking on a Magical Journey',
      link: 'https://readtheforum.org/25867/project-week-2025/latin-students-spend-p-week-embarking-on-a-magical-journey/',
      author: 'Mia Kotler',
      date: 'March 15, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/NVzLmG1ssFFBmQfG47KwdAhG3TrVtqoZN71173FL.png'
    }
  },
  '3/19/2025': {
    word: 'SLEEP',
    story: {
      title: 'Latin Romans Need to Start Prioritizing Sleep',
      link: 'https://readtheforum.org/25674/features/latin-romans-need-to-start-prioritizing-sleep/',
      author: 'Isabella Hudson',
      date: 'February 18, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/gupfWg9c7Dc2L0vR0dlu1IMWKmtxNhwOW0DEeXaK.jpg'
    }
  },
  '3/20/2025': {
    word: 'MONEY',
    story: {
      title: 'Time is Money: The Fate of Latin’s Dearborn Property Investment Remains Unclear',
      link: 'https://readtheforum.org/25753/features/time-is-money-the-fate-of-latins-dearborn-property-investment-remains-unclear/',
      author: 'Mia Kotler and Marin Ralson',
      date: 'March 7, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/a51JEI6Wkaw63Ada7Pj04ElGirddbSIfzMvdaXvq.jpg'
    }
  },
  '3/21/2025': {
    word: 'WASTE',
    story: {
      title: 'Latin Pilots WasteNot Compost Bins',
      link: 'https://readtheforum.org/25159/news/latin-pilots-wastenot-compost-bins/',
      author: 'Scarlet Gitelson and Sloane Singerman',
      date: 'February 3, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/HKIdvtzCRL1i3Y8qX5G0Hl70dCaV2mwLZWazGNb7-1200x891.png'
    }
  },
  '3/22/2025': {
    word: 'GAMES',
    story: {
      title: 'Squid Game Returns For Another Round',
      link: 'https://readtheforum.org/25791/arts/squid-game-returns-for-another-round/',
      author: 'Anderson Miller',
      date: 'March 7, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/5VZWb7DV5LeoiDZM0kRhminv1IkIenfGsgZQnevO.jpg'
    }
  },
  '3/23/2025': {
    word: 'HURRY',
    story: {
      title: 'Hurry Up Tomorrow: The Weeknd’s Last Hurrah',
      link: 'https://readtheforum.org/25787/arts/hurry-up-tomorrow-the-weeknds-last-hurrah/',
      author: 'Miles Heltzer',
      date: 'March 7, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/jeyJHJDhKCY9m5COpK7CRNPydqlct6nScNc6dFRJ.jpg'
    }
  },
  '3/24/2025': {
    word: 'WITTY',
    story: {
      title: 'Student Actors Bring Shakespeare’s Wit and Romance to Life in ‘Much Ado About Nothing’',
      link: 'https://readtheforum.org/25563/arts/student-actors-bring-shakespeares-wit-and-romance-to-life-in-much-ado-about-nothing/',
      author: 'Anderson Miller and Carla Di Silvestro',
      date: 'February 18, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/GhByr9RZArsXGacN3WFegGWNFt9gEG16XLBUKyFS.jpg'
    }
  },
  '3/25/2025': {
    word: 'FRESH',
    story: {
      title: 'Viola Wong Looks Forward Through Freshman Year',
      link: 'https://readtheforum.org/25646/features/student-of-the-week/viola-wong-looks-forward-through-freshman-year/',
      author: 'Scarlet Gitelson',
      date: 'February 18, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/783wirEmDolvrrz96VA3S2HdKynRmDleZ2e2D0os-e1739931776577.jpg'
    }
  },
  '3/26/2025': {
    word: 'PHONE',
    story: {
      title: 'Phone Mirroring: The Loophole to Phone Boxes',
      link: 'https://readtheforum.org/25468/op/phone-mirroring-the-loophole-to-phone-boxes/',
      author: 'Miles Heltzer',
      date: 'February 4, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/ozvahIdZDSFQopxEjhXPWdLuMUoJBX1FEuzujrCO.jpg'
    }
  },
  '3/27/2025': {
    word: 'POINT',
    story: {
      title: '1,000 Points and Counting: Roya Smith’s Impact on Latin Girls Basketball',
      link: 'https://readtheforum.org/25762/sports/1000-points-and-counting-roya-smiths-impact-on-latin-girls-basketball/',
      author: 'Cece Burt',
      date: 'March 7, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/w7HFHx6LPZWGzcGR764BtNRod9NnFmcdvDrn07U1-1200x900.png'
    }
  },
  '3/28/2025': {
    word: 'ROCKY',
    story: {
      title: 'Rocky Road for the Romans as Boys Fall Short in the Big Game',
      link: 'https://readtheforum.org/25250/sports/rocky-road-for-the-romans-as-boys-fall-short-in-the-big-game/',
      author: 'Cherish Curtis',
      date: 'February 3, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/GZsCNOsLppRU9U3prqgNZ0eHMWe647oTIbhblz4i.jpg'
    }
  },
  '3/29/2025': {
    word: 'LATIN',
    story: {
      title: 'Love and Legacy: How the Allens Found Their Forever at Latin School',
      link: 'https://readtheforum.org/25191/latin-love-stories/love-and-legacy-how-the-allens-found-their-forever-at-latin-school/',
      author: 'Sara Cutinho',
      date: 'February 3, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/osEfplE6IaXzPbgLRRjgGrlO7DPwHU0cO0Ouf6QN.jpg'
    }
  },
  '3/30/2025': {
    word: 'TRADE',
    story: {
      title: 'Trade Deadline Drama: Major NBA Moves Stir Fan Reactions',
      link: 'https://readtheforum.org/25586/sports/trade-deadline-drama-major-nba-moves-stir-fan-reactions/',
      author: 'Theo Villers',
      date: 'February 18, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/9PDgWRK2zXXAeZXaBxE27CcrtczIYD58zZspfFqZ-1200x900.jpg'
    }
  },
  '3/31/2025': {
    word: 'PARTY',
    story: {
      title: 'TikTok Banned, Then Restored—Where is the Afterparty if the Platform Leaves?',
      link: 'https://readtheforum.org/25072/news/tiktok-banned-then-restored-where-is-the-afterparty-if-the-platform-leaves/',
      author: 'Ellie Anderson',
      date: 'January 20, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/01/3gwdz8kKGzj7GH4SYP7iZ5GjbBXWIYti8XHcTPvq.jpg'
    }
  },
  '4/1/2025': {
    word: 'PICKS',
    story: {
      title: 'Pleasing, Picking, Placing: Teacher Recommendation Letters',
      link: 'https://readtheforum.org/24951/features/pleasing-picking-placing-teacher-recommendation-letters/',
      author: 'Anderson Miller',
      date: 'January 15, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/01/mYZsuBQzMiWIjGnjDj6RMrlqH2BMeANQiWuVAbWw-1200x1104.jpg'
    }
  },
  '4/2/2025': {
    word: 'POINT',
    story: {
      title: '1,000 Points and Counting: Roya Smith’s Impact on Latin Girls Basketball',
      link: 'https://readtheforum.org/25762/sports/1000-points-and-counting-roya-smiths-impact-on-latin-girls-basketball/',
      author: 'Cece Burt',
      date: 'March 7, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/03/w7HFHx6LPZWGzcGR764BtNRod9NnFmcdvDrn07U1-1200x900.png'
    }
  },
  '4/3/2025': {
    word: 'DEBUT',
    story: {
      title: 'Izzy Schafer Debuts New Film Project, Titled ‘Untamed’',
      link: 'https://readtheforum.org/25520/features/izzy-schafer-debuts-new-film-project-titled-untamed/',
      author: 'Sloane Singerman',
      date: 'February 18, 2025',
      image: 'https://readtheforum.org/wp-content/uploads/2025/02/dRrQdK9AkJb7q1mD3fKM0ojTKQtHTa6LMq77Xbiz.jpg'
    }
  }
};